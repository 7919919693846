<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
import FormAdd from "./add-kantor.vue";
import FormEdit from "./edit-kantor.vue";

export default {
  page: {
    title: "Kantor",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    FormAdd,
    FormEdit,
  },
  data() {
    return {
      title: "Kantor",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Kantor",
          active: true,
        },
      ],
      // variable Page Table
      loadingTable: true,
      table_data: [],
      pagingTable: [],
      showDataToTable: "",
      showDataFromTable: "",
      totalDataTable: "",
      currentTablePage: "",
      searchDataTable: "",
      options_status: ["ENABLE", "DISABLE"],
      selected: [],
      status: "ENABLE",
      search: "",

      //modal
      showModalAdd: false,
      showFormAdd: false,
      showModalEdit: false,
      showFormEdit: false,
      idDataEdit: 0,
    };
  },
  mounted() {
    this.getDataTable();
  },
  methods: {
    getDataTable() {
      let self = this;
      self.loadingTable = true;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "/api/master/kantor?status=" +
            self.status +
            "&search=" +
            self.search
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            self.table_data = response_data.list_data.data;
            self.loadingTable = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
        });
    },
    hapusData(jb_id) {
      var alert_text = "Apakah Anda yakin?";
      Swal.fire({
        title: "Warning ?",
        text: alert_text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
      }).then((result) => {
        if (result.isConfirmed) {
          let self = this;
          Swal.fire({
            title: '<i class="fas fa-spinner fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });
          var FormData = require("form-data");
          var data = new FormData();
          data.append("id", jb_id);

          var config = {
            method: "post",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "/api/master/kantor/hapus",
            data: data,
          };
          axios(config)
            .then(function (response) {
              console.log(response);
              let timerInterval;
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Anda akan diarahkan ke halaman master kantor segera",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
                willClose: () => {
                  clearInterval(timerInterval);
                },
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  Swal.close();
                  self.getDataTable();
                }
              });
            })
            .catch((e) => {
              this.axiosCatchError = e.response.data.data;
              Swal.close();
            });
        } else {
          Swal.close();
        }
      });
    },
    //filter data
    filterData() {
      this.getDataTable();
    },
    //multiple delete
    selectItem() {
      let self = this;
      console.log(self.selected);
    },
    //delete data multiple
    deleteselected() {
      let self = this;
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin menghapus data tersebut?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });
          var FormData = require("form-data");
          var data = new FormData();
          data.append("id", self.selected);
          var config = {
            method: "POST",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "api/master/kantor/multiplehapus",
            data: data,
          };
          axios(config).then((response) => {
            console.log(response);
            let timerInterval;
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Data berhasil dihapus.",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
              didOpen: () => {
                timerInterval = setInterval(() => {
                  const content = Swal.getContent();
                  if (content) {
                    const b = content.querySelector("b");
                    if (b) {
                      b.textContent = Swal.getTimerLeft();
                    }
                  }
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                self.getDataTable();
              }
            });
          });
        } else {
          Swal.close();
        }
      });
    },
    //change status data
    changeStatusData(status, id) {
      let self = this;
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin mengubah status data?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });
          var FormData = require("form-data");
          var data = new FormData();
          data.append("status", status);
          data.append("id", id);
          var config = {
            method: "POST",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "api/master/kantor/ubahstatus",
            data: data,
          };
          axios(config).then((response) => {
            console.log(response);
            let timerInterval;
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Status data berhasil diubah.",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
              didOpen: () => {
                timerInterval = setInterval(() => {
                  const content = Swal.getContent();
                  if (content) {
                    const b = content.querySelector("b");
                    if (b) {
                      b.textContent = Swal.getTimerLeft();
                    }
                  }
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                self.getDataTable();
              }
            });
          });
        } else {
          Swal.close();
        }
      });
    },
    eksporHandle() {
      const link = document.createElement("a");
      link.href = process.env.VUE_APP_BACKEND_URL+"/fitur/ekspor?x=kantor&status="+this.status;
      link.setAttribute('target', '_blank');
      link.click();
    },
    formAddData() {
      let self = this;
      self.showModalAdd = false;
      self.showFormAdd = false;
      // config untuk re render component
      self.$nextTick(() => {
        self.showModalAdd = true;
        self.showFormAdd = true;
      });
    },
    formEditData(idDataEdit) {
      let self = this;
      self.showModalEdit = false;
      self.showFormEdit = false;
      // config untuk re render component
      self.$nextTick(() => {
        self.showModalEdit = true;
        self.showFormEdit = true;
        self.idDataEdit = idDataEdit;
      });
    },

    refreshTable(type) {
      let self = this;
      if (type == "add") {
        self.showModalAdd = false;
        self.showFormAdd = false;
      } else {
        self.showModalEdit = false;
        self.showModalEdit = false;
      }
      self.getDataTable();
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <br />
                <div class="row">
                  <div class="col-md-12">
                    <div class="filter">
                      <div class="row" style="margin-bottom: 10px">
                        <div class="col-md-2">
                          <label for="">Status</label>
                          <select
                            class="form-control input-sm"
                            v-model="status"
                          >
                            <option value="ENABLE">ENABLE</option>
                            <option value="DISABLE">DISABLE</option>
                          </select>
                        </div>
                        <div class="col-md-2">
                          <button
                            class="btn btn-primary"
                            @click="filterData"
                            type="button"
                            style="margin-top: 25px; border-radius: 0px"
                          >
                            <i class="fa fa-filter"></i> Filter
                          </button>
                        </div>
                        <div class="col-md-8 mt-4">
                          <div class="text-end">
                            <button type="button" v-on:click="formAddData" class="btn btn-success m-1 rounded-0">
                              <i class="fa fa-plus"></i> Tambah Kantor
                            </button>
                            <button @click="eksporHandle" class="btn btn-warning m-1"
                              ><i class="fas fa-file-excel"></i> Ekspor Kantor</button
                            >
                            <a href="#" class="btn btn-info btn-sm m-1 d-none"
                              ><i class="fa fa-file"></i> Import Kantor</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    &nbsp;
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-9"></div>
                  <div class="col-md-3">
                    <input
                      type="text"
                      class="form-control"
                      id="searchTable"
                      v-model="search"
                      @input="getDataTable()"
                      placeholder="Cari Data ..."
                    />
                  </div>
                </div>
                <br />
              </div>
              <div class="col-md-12">
                <div class="table-responsive">
                  <table class="table mb-0 table-bordered table-condensed table-hover table-striped">
                    <thead
                      class="text-center text-white"
                      style="background-color: #132d4a"
                    >
                      <tr>
                        <th style="width: 50px">#</th>
                        <th style="width: 50px">No</th>
                        <th style="width: 150px">Kantor</th>
                        <th style="width: 100px">Status</th>
                        <th style="width: 175px">Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="loadingTable">
                        <td colspan="5">
                          <i class="fas fa-spinner fa-spin"></i> Loading...
                        </td>
                      </tr>
                      <tr v-else-if="table_data == ''">
                        <td class="text-center" colspan="6">
                          Data Tidak Tersedia
                        </td>
                      </tr>
                      <tr
                        v-else
                        v-for="(row_data, key_data) in table_data"
                        :key="key_data"
                      >
                        <td class="text-center">
                          <input
                            type="checkbox"
                            v-model="selected"
                            @change="selectItem"
                            :value="row_data.id"
                          />
                        </td>
                        <td class="text-center">
                          {{ key_data + 1 }}
                        </td>
                        <td class="text-center">
                          {{ row_data.kantor }}
                        </td>
                        <td class="text-center">
                          <div v-if="row_data.status == 'ENABLE'">
                            <button
                              type="button"
                              class="btn btn-success btn-sm"
                              v-on:click="
                                changeStatusData('DISABLE', row_data.id)
                              "
                            >
                              <i class="bx bx-check-double"></i> ENABLE
                            </button>
                          </div>
                          <div v-else>
                            <button
                              type="button"
                              class="btn btn-danger btn-sm"
                              v-on:click="
                                changeStatusData('ENABLE', row_data.id)
                              "
                            >
                              <i class="fas fa-undo"></i> DISABLE
                            </button>
                          </div>
                        </td>
                        <td class="text-center">
                          <div class="btn-group">
                            <button
                              type="button"
                              v-on:click="formEditData(row_data.id)"
                              class="btn btn-sm btn-primary"
                            >
                              <i class="fa fa-edit"></i> Edit
                            </button>
                            <button
                              type="button"
                              class="btn btn-sm btn-danger"
                              v-on:click="hapusData(row_data.id)"
                            >
                              <i class="fa fa-trash"></i> Hapus
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <button
                  href="#"
                  class="btn btn-danger m-1"
                  @click="deleteselected"
                >
                  <i class="fa fa-trash"></i> Hapus Data Terpilih
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
  <b-modal v-model="showModalAdd" title="Tambah Kantor" title-class="text-black font-18" body-class="p-3" size="md"
    hide-footer>
    <form-add v-if="showFormAdd" @refresh-table="refreshTable('add')"></form-add>
  </b-modal>
  <b-modal v-model="showModalEdit" title="Edit Kantor" title-class="text-black font-18" body-class="p-3" size="md"
    hide-footer>
    <form-edit v-if="showFormEdit" :idDataEdit="idDataEdit" @refresh-table="refreshTable('edit')"></form-edit>
  </b-modal>
</template>
